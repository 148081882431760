/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	font-family: 'Pretendard';
	vertical-align: baseline;
	word-break: keep-all;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	color: #333333;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


html {
    /* 글꼴 크기 조정 방지 */
   -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
   -moz-text-size-adjust: none; /*Firefox*/
   -ms-text-size-adjust: none;  /*Ie*/
   -o-text-size-adjust: none; /*old versions of Opera*/
}
/* Iphone input select box reset */
input , select{-webkit-appearance: none; -webkit-border-radius:0; font: inherit;}

/* textarea */
textarea{resize: none; font: inherit;}

/* input search 'X' remove */
input[type="submit"] , input[type="reset"]{cursor: pointer;}
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal{
    display:none;
}
/* 크롬의 경우 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration{
    display:none;
}

input[type="checkbox"] , input[type="radio"]{display: none;}

a{text-decoration: none; color: inherit; display: inline-block;}

button{background-color: transparent; border: none; cursor: pointer; padding: 0; font: inherit; color: inherit;}

mark{background-color: transparent; color: inherit;}

.editor *:not(font){all: revert;}