@media screen and (min-width : 781px) {
    /* 메인 페이지 */
    .homePage .mainPage > div > div h3{font-size: var(--title02); margin-bottom: 20px;}

    /* 메인 페이지 - 메인 슬라이더 */
    .homePage .mainSwiper{height: 410px; color: white;}
    .homePage .mainSwiper .swiper-slide{background-image: url('../images/mainSliderImg.png'); background-position: center; background-size: auto 100%;}
    .homePage .mainSwiper .swiper-slide div{max-width: var(--mainSliderWidth); width: calc(100% - 40px); margin: 0 auto; padding: 60px 0;}
    .homePage .mainSwiper .swiper-slide div strong{display: block; font-size: var(--title01); font-weight: 700; line-height: 60px; margin-bottom: 10px;}
    .homePage .mainSwiper .swiper-slide div a{font-size: var(--font02); font-weight: 500;}

    /* 메인 페이지 - 메인 슬라이더 - 오토 플레이 */
    .homePage .mainSwiper .playArea{position: absolute; left: 50%; top: 0; transform: translateX(-50%); max-width: var(--mainSliderWidth); width: calc(100% - 40px); width: 100%; height: 100%; display: flex; align-items: flex-end; z-index: 1; pointer-events: none; padding-bottom: 72.5px; box-sizing: border-box;}
    .homePage .mainSwiper .playArea > div{display: flex; align-items: center;}
    .homePage .mainSwiper .playArea > div .progress{display: block; width: 60px; height: 1px; /* background-color: red; */ position: relative;}
    .homePage .mainSwiper .playArea > div .progress span{position: absolute; left: 0; top: 0; width: 0; height: 100%; background-color: white;}
    .homePage .mainSwiper .playArea > div .pager{font-size: 12px; color: #ebebeb; margin-left: 12px;}
    .homePage .mainSwiper .playArea > div .pager span{position: static; background-color: transparent;}
    .homePage .mainSwiper .playArea > div .pager .swiper-pagination-current{color: white;}
    .homePage .mainSwiper .playArea > div button{pointer-events: all; width: 24px; height: 24px; text-indent: -9999px; background-repeat: no-repeat; background-position: center; background-size: cover; margin-left: 8px;}
    .homePage .mainSwiper .playArea > div button.resume{background-image: url('../images/icon-play.png');}
    .homePage .mainSwiper .playArea > div button.pause{background-image: url('../images/icon-pause.png');}

    /* 메인 페이지 - 인기 슬라이더 */
    .popularArea .sliderArea .popularSwiper .swiper-slide{width: 125px; height: 110px;}
    .popularArea .sliderArea .popularSwiper .swiper-slide a{font-size: var(--font01);}


    /* 로그인 페이지 */
    .signPage > section h2 + p{font-size: var(--font03); font-weight: 400; line-height: 26px; margin: 30px 0 50px;}

    /* 로그인 페이지 - 로그인 방식 선택 */
    .signPage > .mainPage ul li:not(:last-child){margin-bottom: 16px;}
    .signPage > .mainPage ul li a{font-size: var(--font03); line-height: 60px;}
    .signPage > .mainPage ul li a::before{left: 26px; width: 24px; height: 24px;}

    /* 로그인 페이지 - 회원가입 */
    .signPage > .signUpPage fieldset .agreeArea{line-height: 26px}
    .signPage > .findPage .tabBox{margin: 50px 0;}


    /* 카테고리 - 메인 */
    .categoryPage :is(.mainPage , .searchPage:has(.searchTotal)) .searchBox{margin-bottom: 50px;}
    .categoryPage .mainPage .categoryType:not(:last-of-type){margin-bottom: 60px;}

    /* 카테고리 - 메인 - 검색*/
    .categoryPage .searchPage .searchTotal{font-size: 17px;}
    .categoryPage .searchPage .searchTotal p{font-weight: 500;}
    .categoryPage .searchPage .searchFail{padding: 170px 0 210px;font-size: 22px;}
    .categoryPage .searchPage .searchFail::before{width: 58px; height: 58px;}

    /* 카테고리 - 보기 */
    .categoryPage .showPage .totalArea{margin-bottom: 20px;}
    .categoryPage .showPage .dropBox-big + .totalArea{margin-top: 50px;}
    .categoryPage .showPage .dropBox-big + .categoryType{margin: 30px 0;}


    /* 랭킹 */
    .rankingPage .mainPage .tabArea li{font-size: var(--font03); padding: 20px 0;}
    .rankingPage .mainPage ol::before{content: ''; position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: calc(100vw - 17px); height: calc(100% + 150px); background-color: #fafafa;}
    .rankingPage .mainPage ol li{width: 48.24%;}
    .rankingPage .mainPage ol li:not(:nth-child(2n)){margin-right: 3.38%;}
    .rankingPage .mainPage ol li:nth-last-child(n + 3){margin-bottom: 30px;}
    .rankingPage .mainPage ol li > a::before{width: 42px; height: 42px; font-size: var(--font02);}
    .marketingPage .mainPage ul li > a{height: 560px; padding: 45px 40px;}


    /* 마케팅 - 메인 */
    .marketingPage .mainPage ul{display: flex; gap: 20px;}
    .marketingPage .mainPage ul li{flex: 1; background-size: cover;}
    .marketingPage .mainPage ul li:nth-child(1){background-image: url('../images/marketing-main01.png');}
    .marketingPage .mainPage ul li:nth-child(2){background-image: url('../images/marketing-main02.png');}
    .marketingPage .mainPage ul li a mark{font-size: var(--font03);}
    .marketingPage .mainPage ul li a p{font-size: 30px; line-height: 36px; margin-top: 15px;}


    /* 마케팅 - 상세 페이지 */
    .marketingPage .detailPage .videoArea{margin-bottom: 60px;}
    .marketingPage .detailPage .fixedArea{bottom: 0px; background-color: var(--colorPoint);}
    .marketingPage .detailPage .fixedArea div{height: 96px; display: flex; justify-content: space-between; align-items: center;}
    .marketingPage .detailPage .fixedArea div p{font-size: 22px; font-weight: 700; color: white; display: flex; align-items: center;}
    .marketingPage .detailPage .fixedArea div p::before{content: ''; width: 28px; height: 28px; background: url('../images/icon-bell.png') no-repeat center / cover; margin-right: 12px;}
    .marketingPage .detailPage .fixedArea div a{max-width: 262px;}

    
    /* 마케팅 - 창업톡 상담 */
    .marketingPage .counselPage h2{text-align: center;}


    /* 상세 페이지 */
    .detailPage section figure{display: flex; justify-content: space-between; margin-bottom: 40px;}
    .detailPage section figure > .imgBox{width: 370px; height: 370px; border-radius: 20px; margin-right: 40px;}
    .detailPage section figure figcaption{width: 310px; display: flex; flex-direction: column; padding-top: 10px;}
    .detailPage section figure figcaption h2{font-size: var(--title02); font-weight: 700; margin-bottom: 16px;}
    .detailPage section figure figcaption h2 mark{display: block; font-size: var(--font06); margin-bottom: 10px;}
    .detailPage section figure figcaption p{font-size: var(--font06);}
    .detailPage section figure figcaption ul li strong{}
    .detailPage section figure figcaption .scrollFixed-bottom div .receiptArea button::before{content: ''; width: 56px; height: 56px; background:#e6f8ee url('../images/icon-bookmark-green.png') no-repeat center / 24px;}

    .detailPage section [class|="scrollFixed"].active{position: fixed; left: 0; width: 100%; padding: 20px 0; background-color: white; z-index: 10;}
    .detailPage section [class|="scrollFixed"].active > div{max-width: var(--contentSize01); margin: 0 auto;}
    .detailPage section .scrollFixed-top.active{top: 86px;}
    /* .detailPage section .scrollFixed-top.active{top: 0;  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);} */
    /* .detailPage section .scrollFixed-top.active .tabBox{margin: 0;} */
    .detailPage section .scrollFixed-top.active .backLink{position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 60px; height: 60px; background-image: url('../images/icon-back.png');}
    .detailPage section figure figcaption .scrollFixed-bottom.active{bottom: 0; box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);}
    .detailPage section figure figcaption .scrollFixed-bottom.active div button{width: 226px; text-indent: 0; font-size: var(--font03); font-weight: 700; color: var(--colorPoint); display: flex; justify-content: center; align-items: center; background-image: none;}
    .detailPage section figure figcaption .scrollFixed-bottom.active div button::before{content: ''; width: 24px; height: 24px; margin-right: 4px;}
    .detailPage section mark.support strong{padding: 10px 30px; margin-right: 30px;}
    .detailPage section .tabBox{margin-bottom: 50px;}
    .detailPage section > div > div > div:not(:last-child){margin-bottom: 70px;}
    .detailPage section > div > div h3{font-size: var(--font01); font-weight: 700; margin-bottom: 14px;}

    /* 상세 페이지 - 브랜드 정보 */
    .detailPage .videoArea iframe{height: 340px;}
    .detailPage .detailArea div{height: 1000px;}
    .detailPage .detailArea button{width: 100%; height: 60px; display: flex; justify-content: center; align-items: center; font-size: var(--font03); font-weight: 700; border: 1px solid #ccc; border-radius: 10px;}
    .detailPage .detailArea button::after{content: ''; width: 34px; height: 34px; background: url('../images/icon-category-more.png') no-repeat center / cover; margin-left: 2px;}
    .detailPage section .infoArea .menuArea .swiper-slide{width: 210px;}
    .detailPage section .infoArea .menuArea .swiper-slide:not(:last-child){margin-right: 20px;}
    .detailPage section .infoArea .interiorArea .swiper-slide .imgBox,
    .detailPage section .infoArea .locationArea iframe{height: 340px;}
    .detailPage section .infoArea .locationArea iframe{margin-bottom: 16px;}
    .detailPage section .infoArea .locationArea p{font-size: var(--font06);}
    .detailPage section .infoArea .interiorArea .navigation-circle{top: 0; transform: translate(-50%);}
    .detailPage section .infoArea .interiorArea .navigation-circle .prev::after{transform: translateX(20px);}
    .detailPage section .infoArea .interiorArea .navigation-circle .next::after{transform: translateX(-20px);}

    /* 상세 페이지 - 창업개요 */
    .detailPage section .counselArea div table tbody tr{font-size: 16px; line-height: 70px;}
    .detailPage section .counselArea div table tbody tr th{width: 236px;}
    .detailPage section .counselArea div table tbody tr td{width: 264px;}
    .detailPage section .counselArea div table tfoot tr th{padding: 33px 50px;}
    .detailPage section .counselArea div table tfoot tr th strong{font-size: var(--font01); margin-bottom: 8px;}


    /* 간편 상담 신청 */
    .receiptPage .mainPage fieldset > div label{font-size: var(--font06);}

    /* my 페이지 */
    .myPage h3{font-size: var(--title02); font-weight: 700; margin-bottom: 28px;}
    .appLink{background-image: url('../images/appLinkBG.png'); height: 104px; display: flex; align-items: center; justify-content: center;}
    .appLink mark{font-size: 22px; margin-right: 4.375%;}
    .appLink a{width: 248px;}

    /* my 페이지 - 최상단 */
    .myPage .topArea{padding: 50px 0 40px; margin-bottom: 60px;}
    .myPage .topArea div h2{font-size: 32px; margin-bottom: 110px;}
    .myPage .topArea div h2 a{text-decoration: underline;}
    .myPage .topArea div .logout{position: absolute; left: 0; bottom: 0; font-size: var(--font04); color: rgba(255, 255, 255, 0.8); display: flex; align-items: center;}
    .myPage .topArea div .logout::after{content: ''; width: 20px; height: 20px; background: url('../images/icon-logout.png') no-repeat center / cover; margin-left: 6px;}
    .myPage .topArea div ul{display: flex; justify-content: flex-end; align-items: center;}
    .myPage .topArea div ul li:not(:last-child){margin-right: 50px;}
    .myPage .topArea div ul li a{font-size: var(--font01);}
    .myPage .topArea div ul li:first-child a{position: relative;}
    .myPage .topArea div ul li:first-child a::after{content: ''; position: absolute; right: -9px; top: -2px; width: 6px; height: 6px; background-color: rgba(255, 255, 255, 0.7); border-radius: 100%;}

    /* my 페이지 - 메인 */
    .myPage .mainPage .tabBox{margin-bottom: 40px;}

    /* my 페이지 - 메인 - 관심 아이템 */
    .myPage .mainPage > button{display: block; width: 205px; height: 60px; font-size: var(--font04); font-weight: 500; border: 1px solid #e6e6e6; border-radius: 30px; margin: 60px auto 0;}

    /* my 페이지 - 메인 - 상담 접수 내역 */
    .myPage .mainPage .boardBox li div{justify-content: space-between; text-align: center;}
    .myPage .mainPage .boardBox li div *:nth-child(2n + 1){width: 150px;}
    .myPage .mainPage .boardBox li div *:nth-child(2){flex: 1;}
    .myPage .mainPage .boardBox li div strong{font-weight: 700;}

    /* my 페이지 - 공지사항 , my 페이지 - 서비스 - 문의 내역*/
    .myPage :is(.noticePage , .servicePage) .boardBox li button{padding: 0 20px;}
    .myPage :is(.noticePage , .servicePage) .boardBox li button strong{width: 92px; font-weight: 700; text-align: left; padding: 0 20px; margin-right: 40px;}
    .myPage :is(.noticePage , .servicePage) .boardBox li button span{padding: 0 20px;}
    .myPage :is(.noticePage , .servicePage) .boardBox li button time{margin-left: auto;}
    .myPage :is(.noticePage , .servicePage) .boardBox li button::after{margin-left: 40px;}
    .myPage :is(.noticePage , .servicePage) .boardBox li > div{padding: 30px 40px 40px;}
    .myPage :is(.noticePage , .servicePage) .boardBox li > div:first-of-type{border-top: 1px solid #dedede;}
    .myPage :is(.noticePage , .servicePage) .boardBox li div.detailArea{min-height: 320px;}

    /* my 페이지 - 계정정보 */
    .myPage .accountPage .inputBox fieldset > .kakao{font-size: var(--font06);}
    .myPage .accountPage .inputBox fieldset > .withdrawalArea{text-align: right;}
    .myPage .accountPage .inputBox fieldset > .withdrawalArea button.withdrawal::after{content: ''; width: 16px; height: 16px; background: url('../images/icon-withdrawal.png') no-repeat center / cover;}

    /* my 페이지 - 서비스 */
    .myPage .servicePage .tabBox{margin-bottom: 50px;}

    /* my 페이지 - 서비스 - 문의하기 */
    .myPage .servicePage .inputBox fieldset div{margin-top: 30px;}
    .myPage .servicePage .inputBox fieldset p{margin-top: 14px;}
    .myPage .servicePage .inputBox fieldset p::before{content: ''; width: 18px; height: 18px; background: url('../images/icon-notice.png') no-repeat center / cover; margin-right: 6px;}
}