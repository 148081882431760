
/* 컨텐츠 사이즈 */
[class*="contentSize"]{width: var(--mobileWidth); margin: 0 auto;}
.contentSize{max-width: var(--contentSize);}
.contentSize01{max-width: var(--contentSize01);}
.contentSize02{max-width: var(--contentSize02);}


/* 접근성 */
.textHidden{position: absolute; top: 0; height: 0.05px; overflow: hidden;}


/* 포인트 색상 */
mark:not([class]){color: var(--colorPoint);}


/* 이미지 박스 */
.imgBox{text-indent: -9999px; background-repeat: no-repeat; background-position: center; background-size: cover;}


/* 버튼 */
[class|="btn"]{width: 100%; font-weight: 700; text-align: center; border-radius: 10px; box-sizing: border-box;}
.btn-basic{background-color: var(--colorPoint); color: white; border: none;}
.btn-border{border: 1px solid rgba(12,191,93,0.3); color: var(--colorPoint);}
.btn-color{background-color: #e6f8ed; color: var(--colorPoint);}
.btn-white{background-color: white; color: var(--colorPoint);}


/* 아이콘 */
[class|="icon"]{text-indent: -9999px; background-repeat: no-repeat; background-position: center; background-size: cover;}
.icon-search{background-image: url('../images/icon-search.png');}


/* 아이템 */
.itemBox{display: block;}
.itemBox:not(.rank) .imgBox{width: 100%; border-radius: 20px;}

/* 아이템 - 기본 */
.itemBox.basic .imgBox{height: var(--itemImgHieght02);}
.itemBox.basic .imgBox:has(span , button){position: relative;}
.itemBox.basic .imgBox span{position: absolute; left: 0; bottom: -8px; text-indent: 0; display: flex; border-radius: 4px;}
.itemBox.basic .imgBox span{font-size: 13px; font-weight: 500; color: white; padding: 5px 10px;}
.itemBox.basic .imgBox span{background-color: var(--colorPoint);}
.itemBox.basic .itemText{font-weight: 500; margin-top: 20px;}
.itemBox.basic .itemText strong{display: block; margin-bottom: 10px;}
.itemBox.basic .itemText div{display: flex; align-items: center;}
.itemBox.basic .itemText div span{color: var(--colorGray); display: flex; align-items: center;}
.itemBox.basic .itemText div span:not(:last-child){margin-right: 8px;}
.itemBox.basic .itemText div span::before{content: ''; width: 18px; height: 18px; background-repeat: no-repeat; background-position: center; background-size: cover; margin-right: 3px;}
.itemBox.basic .itemText div span:nth-child(1)::before{background-image: url('../images/icon-shop-gray.png');}
.itemBox.basic .itemText div span:nth-child(2)::before{background-image: url('../images/icon-cost.png');}
/* .itemBox.basic .itemText div span:nth-child(2)::after{content: '억원';} */
.itemBox.basic .itemText div span:nth-child(3)::before{background-image: url('../images/icon-taik-gray.png');}
/* .itemBox.basic .itemText div span:nth-child(3)::after{content: '천';} */

/* 아이템 - 베스트 */
.itemBox.best .imgBox{height: var(--itemImgHieght01);}

/* 아이템 - 랭킹 */
.itemBox.rank{display: flex; position: relative;}
.itemBox.rank .itemText{flex: 1; display: flex; flex-direction: column; justify-content: center;}
.itemBox.rank .itemText mark{font-size: var(--font06);}
.itemBox.rank .itemText strong{font-size: var(--font01); font-weight: 700; margin-bottom: 17px;}
.itemBox.rank .itemText span{display: flex; align-items: center;}
.itemBox.rank .itemText span::before{content: ''; width: 16px; height: 16px; background-repeat: no-repeat; background-position: center; background-size: cover; margin-right: 4px;}
.itemBox.rank .itemText span.chat::before{background-image: url('../images/icon-taik.png');}
.itemBox.rank .itemText span.store::before{background-image: url('../images/icon-shop.png');}
.itemBox.rank .itemText span.interest::before{background-image: url('../images/icon-interest.png');}

/* 아이템 - 가격 */
.itemBox.price .itemText{text-align: center;}

/* 북마크 */
.itemBox .imgBox button{position: absolute; right: 20px; top: 18px; text-indent: inherit; background-repeat: no-repeat; background-position: center; background-size: cover;}
.itemBox:not(.rank) .imgBox button{background-image: url('../images/icon-bookmark-white.png');}
.itemBox:not(.rank) .imgBox button.active{background-image: url('../images/icon-bookmark-white-chk.png');}
.itemBox.rank .imgBox button{background-image: url('../images/icon-bookmark-border.png');}
.itemBox.rank .imgBox button.active{background-image: url('../images/icon-bookmark-border-chk.png');}

/* 아이템 - 베스트  순위표? */
.bestList :is(.swiper-slide){counter-increment: bestItem;}
.bestList :is(.swiper-slide) .itemText{margin-top: 16px; padding: 5px 0 12px 64px; position: relative;}
.bestList :is(.swiper-slide) .itemText::before{content: '베스트' counter(bestItem); position: absolute; left: 0; top: 0; width: 52px; height: 62px; padding: 6px 5px; font-size: 14px; font-weight: 500; color: white; line-height: 20px; text-align: center; background: url('../images/icon-best.png') no-repeat center / cover; box-sizing: border-box; word-break: normal;}
.bestList :is(.swiper-slide) .itemText mark{display: block; font-weight: 700;}
.bestList :is(.swiper-slide) .itemText strong{font-weight: 500;}
.bestList :is(.swiper-slide) .itemText span{position: absolute; right: 0; top: 0; font-weight: 700; display: flex; align-items: center;}
.bestList :is(.swiper-slide) .itemText span::before{content: ''; width: 16px; height: 16px; background: url('../images/icon-interest.png') no-repeat center / cover; margin-right: 4px;}
.bestList :is(.swiper-slide) .itemText span::after{content: ''; width: 12px; height: 12px; background: url('../images/icon-up.png') no-repeat center / cover; margin-left: 4px;}

/* 슬라이더 공통 - 컨트롤 공통 */
[class|="navigation"]{position: absolute; left: 50%; max-width: var(--contentSize); width: 100%; height: 100%; display: flex; justify-content: space-between; z-index: 1; pointer-events: none;}
[class|="navigation"] :is(.prev , .next){width: var(--iconSize02); height: 100%; display: flex; align-items: center;}
[class|="navigation"] :is(.prev , .next)::after{content: ''; width: 100%; height: var(--iconSize02); background-repeat: no-repeat; background-position: center; background-size: cover; cursor: pointer; pointer-events: all;}
.navigation-basic , .popularArea .navigation-circle{top: 50%; transform: translate(-50% , -50%);}
.itemSwiper + .navigation-circle{top: 0; transform: translateX(-50%);}
.itemArea .itemSwiper + .navigation-circle{height: var(--itemImgHieght02);}
.bastArea .itemSwiper + .navigation-circle{height: var(--itemImgHieght01);}
.navigation-basic .prev::after{background-image: url('../images/icon-prev-basic.png');}
.navigation-basic .next::after{background-image: url('../images/icon-next-basic.png');}
.navigation-circle :is(.prev , .next).swiper-button-disabled{opacity: 0; pointer-events: none;}
.navigation-circle .prev::after{background-image: url('../images/icon-prev-circle.png'); transform: translateX(-50%);}
.navigation-circle .next::after{background-image: url('../images/icon-next-circle.png'); transform: translateX(50%);}


/* 체크박스 , 라디오 박스 */
label{cursor: pointer;}
input[type="checkbox"] + label:not(:has(mark)){display: flex; align-items: center;}
input[type="checkbox"] + label::before{content: ''; width: 20px; height: 20px; background-image: url('../images/btn-check.png'); background-repeat: no-repeat; background-position: center; background-size: cover; margin-right: 8px;}
input[type="checkbox"] + label:has(mark){text-align: center;}
input[type="checkbox"] + label:has(mark)::before{display: inline-block; vertical-align: sub;}
input[type="checkbox"]:checked + label::before{background-image: url('../images/btn-check-chk.png');}


/* input */
.inputBox{text-align: left;}
.inputBox fieldset > ul > li:not(:last-child){margin-bottom: 20px;}
.inputBox fieldset > ul > li > label{display: block; font-weight: 500; color: #666; margin-bottom: 15px; white-space: pre-line;}
.inputBox fieldset > ul > li :is(input , textarea){width: 100%; background-color: rgba(241, 244, 246, 0.5); border: none; border-radius: 10px; box-sizing: border-box;}
.inputBox fieldset > ul > li textarea{line-height: 20px; height: 200px;}
.inputBox fieldset > ul > li :is(input , textarea)::placeholder,
.searchBox input::placeholder{color: rgba(33, 33, 33, 0.3);}
.inputBox fieldset > ul > li.requi label::after{content: '*'; color: var(--colorPoint);}
.inputBox fieldset > ul > li:has([required]) label::after{content: '*'; color: var(--colorPoint);}
.inputBox fieldset > ul > li div input:read-only{background-color: #e9ebec; color: rgba(33, 33, 33, 0.3);}


/* 서치 박스 */
.searchBox{position: relative;}
.searchBox input{width: 100%; background-color: rgba(241, 244, 246, 0.8); border: none; border-radius: 10px; box-sizing: border-box;}
.searchBox button{position: absolute; top: 50%; transform: translateY(-50%); width: 24px; height: 24px; background: url('../images/icon-search-color.png') no-repeat center / cover; text-indent: -9999px;}


/* 탭 */
.tabBox{display: flex; background-color: #f1f4f6; border-radius: 14px;}
.tabBox li{flex: 1; color: var(--colorGray); font-weight: 600; display: flex; justify-content: center; align-items: center; border-style: solid; border-color: transparent; cursor: pointer;}
.tabBox li.active{border-color: #e6e6e6; /* color: inherit;  */background-color: white;}
.tabBox li mark{color: var(--colorGray); white-space: pre-wrap;}
.tabBox li mark::before{content: ' (';}
.tabBox li mark::after{content: ')';}
.tabBox li.active mark{color: var(--colorPoint);}


/* 카테고리 */
.categoryType ul{display: flex; flex-wrap: wrap;}
.categoryType ul li a{display: block; font-weight: 400; border: 1px solid #e6e6e6; border-radius: 23px;}
.categoryType ul li a mark{font-weight: 700;}
.categoryType ul li a mark::before{content: ' (';}
.categoryType ul li a mark::after{content: ')';}


/* 드랍박스 */
[class|="dropBox"]{position: relative;}
[class|="dropBox"] ul{position: absolute; background-color: white; border: 1px solid #e6e6e6; border-radius: 10px; z-index: 1;}
[class|="dropBox"] ul li a{font-weight: 500; white-space: nowrap;}
[class|="dropBox"] button{display: flex; align-items: center;}
[class|="dropBox"] button::after{content: ''; background: url('../images/icon-category-more.png') no-repeat center / cover;}
[class|="dropBox"] ul li a.active{color: var(--colorPoint);}
.dropBox-big ul{top: calc(100% + 20px); left: 0; padding: 14px 0;}
.dropBox-big ul li a{display: block; padding: 0 22px; font-size: var(--font01); line-height: 52px;}
.dropBox-big button::after{width: 34px; height: 34px; margin-left: 8px;}
.dropBox-small ul{top: calc(100% + 14px); right: 0; padding: 13px 0;}
.dropBox-small ul li a{display: block; padding: 0 26px 0 20px; line-height: 46px;}
.dropBox-small button::after{width: 20px; height: 20px; margin-left: 4px;}


/* 레이아웃 */
/* 레이아웃 - 4개*/
.horizontal4{display: flex; flex-wrap: wrap;}


/* 게시판 */
.boardBox.mobileFull li > *:first-child time{font-size: var(--font05); color: var(--colorGray);}


/* 팝업 */
[class|='popupBox']{position: fixed; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: rgba(0, 0, 0, 0.5); z-index: 100;}
[class|='popupBox'] > div{background-color: white; width: var(--mobileWidth); border-radius: 8px;}
.popupBox-termsOfUse > div{box-sizing: border-box; display: flex; flex-direction: column; position: relative;}
.popupBox-termsOfUse > div strong{font-weight: 700;}
.popupBox-termsOfUse > div .textArea{flex: 1; overflow: auto;}
.popupBox-termsOfUse > div .textArea::-webkit-scrollbar{background-color: transparent; width: 6px; border-radius: 4px;}
.popupBox-termsOfUse > div .textArea::-webkit-scrollbar-thumb{background-color: #e5e5e5;}
.popupBox-termsOfUse > div .textArea ol li{line-height: 24px;}
.popupBox-termsOfUse > div .textArea ol li b{display: block; font-weight: 700;}
.popupBox-termsOfUse > div .btnArea button{background-repeat: no-repeat; background-position: center; background-size: cover; text-indent: -9999px;}
:is(.popupBox-confime , .popupBox-find) > div{max-width: 380px; text-align: center; overflow: hidden;}
:is(.popupBox-confime , .popupBox-find) > div .textArea{padding: 50px 30px; font-size: var(--font03);}
:is(.popupBox-confime , .popupBox-find) > div .textArea b{display: block; font-weight: 700; margin-bottom: 30px;}
:is(.popupBox-confime , .popupBox-find) > div .textArea b + p{font-size: var(--font04);}
:is(.popupBox-confime , .popupBox-find) > div .textArea p{line-height: 24px;}
:is(.popupBox-confime , .popupBox-find) > div .btnArea{display: flex; font-size: var(--font06); font-weight: 700;}
:is(.popupBox-confime , .popupBox-find) > div .btnArea *{flex: 1; height: 54px;}
:is(.popupBox-confime , .popupBox-find) > div .btnArea button{color: white; background-color: var(--colorPoint);}
:is(.popupBox-confime , .popupBox-find) > div .btnArea a{display: flex; justify-content: center; align-items: center; color: var(--colorPoint); background-color: #e6f8ee;}
body:has(.popupBox-bookMark, .popupBox-easyConsult){overflow: hidden;}
.homePage:has(.mainPage):has(.popupBox-bookMark){overflow: hidden;}
.swiper:has(.popupBox-bookMark){z-index: 10;}
:is(.swiper-backface-hidden .swiper-slide, .swiper-wrapper, .sliderArea):has(.popupBox-bookMark){transform: none !important;}
.homePage .mainPage > div .sliderArea .swiper:has(.popupBox-bookMark){padding: 0;}
/* 나중에 다른 팝업이랑 합쳐야함 */
.popupBox-bookMark div{max-width: 380px; padding: 50px 30px 24px; text-align: center; box-sizing: border-box;}
.popupBox-bookMark div strong{display: block; font-size: var(--font03); font-weight: 700; line-height: 21px; margin-bottom: 31px;}
.popupBox-bookMark div p{font-size: var(--font04); margin-bottom: 33px;}
.popupBox-bookMark div a{font-size: var(--font06); font-weight: 700; height: 54px; display: flex; justify-content: center; align-items: center; border: 1px solid #e6e6e6; border-radius: 8px;}
.popupBox-bookMark div a:not(:last-of-type){margin-bottom: 14px;}
.popupBox-bookMark div a::before{margin-right: 10px;}
.popupBox-bookMark div a span{width: 90px;}
.popupBox-bookMark div button{font-size: var(--font06); font-weight: 600; color: var(--colorGray); text-decoration: underline; margin-top: 30px;}


/* 간편 상담 접수 팝업 */
.popupBox-easyConsult > div{position: relative;}
.popupBox-easyConsult > div > div{overflow: auto; box-sizing: border-box;}
.popupBox-easyConsult > div >  div::-webkit-scrollbar{width: 5px;}
.popupBox-easyConsult > div >  div::-webkit-scrollbar-thumb{background-color: var(--colorPoint);}
.popupBox-easyConsult > div > div strong{font-weight: 700; text-align: center; display: block;}
.popupBox-easyConsult > div > div .inputBox fieldset > div{margin-top: 30px;}
.popupBox-easyConsult > div > div .inputBox fieldset ul li div:has([type="radio"]){display: flex; background-color: #f1f4f6; border-radius: 14px;}
.popupBox-easyConsult > div > div .inputBox fieldset ul li div:has([type="radio"]) label{flex: 1; color: var(--colorGray); font-weight: 600; display: flex; justify-content: center; align-items: center; border-width: 1.5px; border-style: solid; border-color: transparent; cursor: pointer; border-radius: 14px;}
.popupBox-easyConsult > div > div .inputBox fieldset ul li div:has([type="radio"]) [type="radio"]:checked + label{border-color: #e6e6e6; background-color: white;}
.popupBox-easyConsult > div > div .inputBox fieldset ul li div mark{display: block;}
.popupBox-easyConsult > div > div > button{position: absolute; aspect-ratio: 1; background: url(../images/icon-close.png) no-repeat center / cover; text-indent: -9999px;}


/* 해더 */
header{position: sticky; top: 0; font-size: var(--font04); background-color: white; box-shadow: inset 0 -1px 0 0 #dedede; z-index: 10;}
header > div{display: flex; align-items: center;}
header > div h1 a{display: block; background-image: url('../images/logo.png');}
header > div h1 a img{width: 100%;}
header > div div a.centerLink{color: var(--colorPoint); font-weight: 700; display: flex; align-items: center; /* border-left: 1px solid #d8d8d8; */}
/* border left 값 일단 주석 로그인 버튼이 없기 때문에 - 나중에 로그인 살릴 때 살려야 한다 */
header > div div a.centerLink::after{content: ''; aspect-ratio: 1 / 1; background: url('../images/gopartner.png') no-repeat center / cover;}

/* 푸터 */
footer{box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);}
footer div dl{font-size: var(--font06); display: flex; align-items: center;}
footer div dl dt{font-weight: 400;}
footer div dl dd{font-weight: 700; display: flex; align-items: center;}
[class*='beforeIcon']{display: flex; align-items: center;}
[class*='beforeIcon']::before{content: ''; aspect-ratio: 1; background-repeat: no-repeat; background-position: center; background-size: cover;}
.beforeIcon-gp::before{background-image: url('../images/icon-gp.png');}
.beforeIcon-apple::before{background-image: url('../images/icon-apple.png');}
footer div dl dd:not(:last-child)::after{content: ''; width: 1px; background-color: #d8d8d8;}
footer div ul{display: flex; align-items: center;}
footer div ul li{font-size: var(--font06);}
footer div ul li:last-child{font-weight: 700;}

