@media screen and (max-width : 780px) {

    /* 모바일 가로 크기 풀 */
    .mobileFull{transform: translateX(-20px); width: calc(100% + 40px);}
    .mobileFull .swiper{padding: 0 20px;}

    /* 페이지 컨텐츠 공통 */
    /* 개별 페이지 컨텐츠 사이즈 */
    [class$="Page"] [class$="Page"]{width: var(--mobileWidth); margin-left: auto; margin-right: auto; margin-bottom: 100px;}
    /* width 1140 - 메인 페이지 , 카테고리 페이지 , 랭킹 페이지 , 마이페이지 , 마케팅 메인*/
    :is(.homePage , .categoryPage , .rankingPage , .myPage ) [class$="Page"],
    .marketingPage .mainPage{max-width: var(--contentSize);}
    /* width 720 - 상세 페이지*/
    .detailPage > section,
    .marketingPage .detailPage {max-width: var(--contentSize01);}
    /* width 390 - 로그인 페이지 , 마케팅 상담 페이지 */
    :is(.signPage , .receiptPage) [class$="Page"],
    .marketingPage .counselPage{max-width: var(--contentSize02);}

    /* 페이지 컨텐츠 상단 공간 */
    /* 로그인  , 간편 상담 페이지 */
    :is(.signPage , .receiptPage) [class$="Page"],
    .marketingPage .counselPage{margin-top: 50px;}
    /* 메인 페이지 */
    .homePage [class$="Page"]{margin-top: 50px;}
    /* 카테고리 , 랭킹  , 마이 , 상세 , 마케팅 - 메인 페이지*/
    :is(.categoryPage , .rankingPage , .myPage ) > section,
    .marketingPage :is(.mainPage , .detailPage){margin-top: 30px;}


    /* 서브 페이지 타이틀 공통 */
    :is(.signPage , .categoryPage , .marketingPage) .mainPage h2, 
    :is(.rankingPage) > section h2,
    .myPage .mobileSettingPage h2,
    .categoryPage .searchPage h2{font-size: var(--title01); font-weight: 700;}

    :is(.categoryPage , .marketingPage) .mainPage h2,
    :is(.rankingPage) > section h2,
    .myPage .mobileSettingPage h2,
    .categoryPage .searchPage h2{margin-bottom: 30px;}

    .signPage > section:not(.mainPage) h2,
    .receiptPage > .mainPage h2,
    .marketingPage .counselPage h2,
    .myPage h3{position: fixed; top: 0; left: 50%; transform: translateX(-50%); z-index: 11; line-height: 50px; font-size: var(--font04); font-weight: 700;}


    /* 버튼 */
    [class|="btn"]{line-height: 50px; font-size: var(--font04);}
    [class|="btn"] + [class|="btn"]{margin-top: 12px;}


    /* 아이템 - 기본 */
    .itemBox.basic .itemText strong{font-size: var(--font02);}
    .itemBox.basic .itemText div span{font-size: var(--font05);}

    /* 아이템 - 랭킹 */
    .itemBox.rank .imgBox{width: 98px; height: 98px; border-radius: 18px; margin-right: 16px;}
    .itemBox.rank .itemText mark{font-weight: 600; margin-bottom: 7px;}
    .itemBox.rank .itemText strong{font-size: var(--font02); font-weight: 600; margin-bottom: 20px;}
    .itemBox.rank .itemText span{font-size: 12px; font-weight: 700;}

    /* 아이템 - 가격 */
    .itemBox.price .imgBox{height: 98px; margin-bottom: 9px;}
    .itemBox.price .itemText{font-size: var(--font04); line-height: 18px;}
    .itemBox.price .itemText strong{font-weight: 600;}

    /* 북마크 */
    .itemBox .imgBox button{width: 22px; height: 22px;}
    .itemBox.rank .imgBox button{top: 10px; right: 0; width: 24px; height: 24px;}

    /* 아이템 - 베스트  순위표? */
    .bestList :is(.swiper-slide) .itemText mark{font-size: var(--font05); margin-bottom: 12px;}
    .bestList :is(.swiper-slide) .itemText strong{font-size: var(--font02);}
    .bestList :is(.swiper-slide) .itemText span{font-size: var(--font05);}
    


    /* input */
    .inputBox{font-size: var(--font04);}
    .inputBox fieldset > ul > li label{font-size: var(--font06); line-height: 20px;}
    .inputBox fieldset > ul > li > *:not(:last-child){margin-bottom: 10px;}
    .inputBox fieldset > ul > li input{height: 48px;}
    .inputBox fieldset > ul > li :is(input , textarea){padding: 16px;}
    .inputBox input[type="submit"]{margin-top: 50px;}


    /* 서치 박스 */
    .searchBox input{height: 48px;  padding: 15px 50px 15px 15px;}
    .searchBox button{right: 16px;}


    /* 탭 */
    .tabBox li{font-size: var(--font04); height: 50px; border-width: 1px; border-radius: 10px;}


    /* 카테고리 */
    .categoryType h3{font-size: var(--font01); font-weight: 600; height: 70px; display: flex; justify-content: space-between; align-items: center; cursor: pointer;}
    .categoryType h3::after{content: ''; width: 24px; height: 24px; background: url('../images/m-icon-category-more.png') no-repeat center / cover; transition: transform 0.5s;}
    .categoryType h3.active::after{transform: rotate(180deg);}
    .categoryType ul{gap: 12px 10px;}
    .categoryType ul li a{font-size: var(--font04); padding: 14px 16px;}


    /* 드랍박스 */
    .dropBox-small ul li a{font-size: var(--font03);}


    /* 레이아웃 */
    /* 레이아웃 - 4개*/
    @media (min-width: 601px) {
        .horizontal4 > li{width: calc((100% - 20px) / 2);}
        .horizontal4 > li:not(:nth-child(2n)){margin-right: 20px;}
        .horizontal4 > li:nth-child(n + 3){margin-top: 30px;}
    }
    @media (max-width: 600px) {
        .horizontal4 > li{width: 100%;}
        .horizontal4 > li:nth-child(n + 2){margin-top: 30px;}
    }


    /* 게시판 */
    .boardBox{font-size: var(--font04);}
    .boardBox li{border-bottom: 1px solid #eeeeee;}
    .boardBox li > *:first-child{display: block; width: 100%; padding-top: 21.5px; padding-bottom: 23.5px; text-align: left;}
    .boardBox.mobileFull li > *:first-child{padding-left: 20px; padding-right: 20px; box-sizing: border-box;}
    .boardBox.mobileFull li > *:first-child > *:first-child{display: block; margin-bottom: 10px;}
    .boardBox.mobileFull li:has(button){position: relative;}
    .boardBox.mobileFull li button::after{position: absolute; right: 20px; top: 18.5px;}


    /* 팝업 */
    .popupBox-termsOfUse > div{width: 100%; height: 100%; padding: 80px 20px 30px; box-sizing: border-box; border-radius: 0;}
    .popupBox-termsOfUse > div strong{font-size: var(--title01); margin-bottom: 18px;}
    .popupBox-termsOfUse > div .textArea ol li{font-size: var(--font04);}
    .popupBox-termsOfUse > div .textArea ol li b{margin-bottom: 20px;}
    .popupBox-termsOfUse > div .btnArea{position: absolute; left: 0; top: 0; width: 100%; display: flex; justify-content: space-between; align-items: center;}
    .popupBox-termsOfUse > div .btnArea button{background-image: url('../images/m-icon-close.png'); width: 50px; height: 50px;}


    /* 간편 상담 접수 팝업 */
    .popupBox-easyConsult > div{width: 100%; height: 100%;}
    .popupBox-easyConsult > div > div{padding: 40px 30px; max-height: 100%;}
    .popupBox-easyConsult > div > div strong{font-size: var(--title01); margin-bottom: 18px;}
    .popupBox-easyConsult > div > div .inputBox fieldset ul li div:has([type="radio"]) label{font-size: var(--font04); height: 50px; border-width: 1px; border-radius: 10px;}
    .popupBox-easyConsult > div > div .inputBox fieldset ul li div mark{margin-top: 10px;}
    .popupBox-easyConsult > div > div > button{width: 20px; right: 10px; top: 10px;}


    /* 해더 */
    header > div{justify-content: space-between; padding: 13px 0;}
    header > div h1{width: 68px;}
    header > div h1 a{width: 68px; height: 24px;}
    header > div div{display: flex; align-items: center; gap: 15px;}
    header > div div a:not([class]){font-size: 13px; text-align: center; width: 66px; line-height: 32px; border-radius: 16px; border: 1px solid #e6e6e6;}
    header > div div a.centerLink{padding-left: 15px;}
    header > div div a.centerLink::after{width: 15px; margin-left: 5px;}
    header > div nav{position: fixed; left: 0; bottom: 0; width: 100%; box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.1);}
    header > div nav ul{display: flex; background-color: white;}
    header > div nav ul li{flex: 1; font-size: 10px; font-weight: 500; color: var(--colorGray);}
    header > div nav ul li a{display: flex; justify-content: center; align-items: flex-end; height: 54px; padding: 6px 10px; box-sizing: border-box; background-repeat: no-repeat; background-position: top 5px center; background-size: 30px;}
    header > div nav ul li a.active{color: var(--colorBasic);}
    header > div nav ul li:nth-child(1) a{background-image: url('../images/m-icon-home-gray.png');}
    header > div nav ul li:nth-child(2) a{background-image: url('../images/m-icon-cartegory-gray.png');}
    header > div nav ul li:nth-child(3) a{background-image: url('../images/m-icon-lanking-gray.png');}
    header > div nav ul li:nth-child(4) a{background-image: url('../images/m-icon-marketing-gray.png');}
    header > div nav ul li:nth-child(1) a.active{background-image: url('../images/m-icon-home.png');}
    header > div nav ul li:nth-child(2) a.active{background-image: url('../images/m-icon-cartegory.png');}
    header > div nav ul li:nth-child(3) a.active{background-image: url('../images/m-icon-lanking.png');}
    header > div nav ul li:nth-child(4) a.active{background-image: url('../images/m-icon-marketing.png');}

    header:has(.backLink){box-shadow: none;}
    .backLink{text-indent: -9999px; width: 50px; height: 50px; background-image: url('../images/icon-back.png');}
    header > div:has(.backLink){padding: 0; width: 100%;}
    header > div:has(.backLink) h1{width: auto;}
    header > div:has(.backLink) h1 a{width: 50px; height: 50px; background-image: url('../images/icon-home.png');}
    

    /* 푸터 */
    footer{padding: 40px 0; margin-bottom: 54px;}
    footer div img{width: 54px; margin-bottom: 20px;}
    footer div dl{margin-bottom: 15px;}
    footer div dl dt{margin-right: 18px;}
    footer div dl dd a::before{margin-right: 6px;}
    [class*='beforeIcon']::before{width: 20px;}
    footer div dl dd:not(:last-child)::after{height: 15px; margin-inline: 18px;}
    footer div ul{gap: 18px;}

    .detailPage footer , .marketingPage .detailPage + footer{margin-bottom: 134px;}
}